<template>
  <div>
    <fabric-material-edit
      v-if="categoryType === 'fabric'"
      :section="section"
      :init-data="initData"
      :style-media="styleMedia"
      :color-options="colorOptions"
      :supplier-options="supplierOptions"
      :submit-handler="submitHandler"
      :fetch-style-material-media="fetchStyleMaterialMedia"
    />
    <label-material-edit
      v-if="categoryType === 'label'"
      :section="section"
      :init-data="initData"
      :style-media="styleMedia"
      :color-options="colorOptions"
      :supplier-options="supplierOptions"
      :submit-handler="submitHandler"
      :fetch-style-material-media="fetchStyleMaterialMedia"
    />
    <button-material-edit
      v-if="categoryType === 'button'"
      :section="section"
      :init-data="initData"
      :style-media="styleMedia"
      :color-options="colorOptions"
      :supplier-options="supplierOptions"
      :submit-handler="submitHandler"
      :fetch-style-material-media="fetchStyleMaterialMedia"
    />
    <drawcord-material-edit
      v-if="categoryType === 'drawcord'"
      :section="section"
      :init-data="initData"
      :style-media="styleMedia"
      :color-options="colorOptions"
      :supplier-options="supplierOptions"
      :submit-handler="submitHandler"
      :fetch-style-material-media="fetchStyleMaterialMedia"
    />
    <elastic-material-edit
      v-if="categoryType === 'elastic'"
      :section="section"
      :init-data="initData"
      :style-media="styleMedia"
      :color-options="colorOptions"
      :supplier-options="supplierOptions"
      :submit-handler="submitHandler"
      :fetch-style-material-media="fetchStyleMaterialMedia"
    />
    <hangtag-material-edit
      v-if="categoryType === 'hangtag'"
      :section="section"
      :init-data="initData"
      :style-media="styleMedia"
      :color-options="colorOptions"
      :supplier-options="supplierOptions"
      :submit-handler="submitHandler"
      :fetch-style-material-media="fetchStyleMaterialMedia"
    />
    <metal-eyelet-material-edit
      v-if="categoryType === 'metal-eyelet'"
      :section="section"
      :init-data="initData"
      :style-media="styleMedia"
      :color-options="colorOptions"
      :supplier-options="supplierOptions"
      :submit-handler="submitHandler"
      :fetch-style-material-media="fetchStyleMaterialMedia"
    />
    <metal-snap-material-edit
      v-if="categoryType === 'metal-snap'"
      :section="section"
      :init-data="initData"
      :style-media="styleMedia"
      :color-options="colorOptions"
      :supplier-options="supplierOptions"
      :submit-handler="submitHandler"
      :fetch-style-material-media="fetchStyleMaterialMedia"
    />
    <tape-material-edit
      v-if="categoryType === 'tape'"
      :section="section"
      :init-data="initData"
      :style-media="styleMedia"
      :color-options="colorOptions"
      :supplier-options="supplierOptions"
      :submit-handler="submitHandler"
      :fetch-style-material-media="fetchStyleMaterialMedia"
    />
    <zipper-material-edit
      v-if="categoryType === 'zipper'"
      :section="section"
      :init-data="initData"
      :style-media="styleMedia"
      :color-options="colorOptions"
      :supplier-options="supplierOptions"
      :submit-handler="submitHandler"
      :fetch-style-material-media="fetchStyleMaterialMedia"
    />
  </div>
</template>
<script>
import ButtonMaterialEdit from './ButtonMaterialEdit.vue'
import DrawcordMaterialEdit from './DrawcordMaterialEdit.vue'
import ElasticMaterialEdit from './ElasticMaterialEdit.vue'
import FabricMaterialEdit from './FabricMaterialEdit.vue'
import HangtagMaterialEdit from './HangtagMaterialEdit.vue'
import LabelMaterialEdit from './LabelMaterialEdit.vue'
import MetalEyeletMaterialEdit from './MetalEyeletMaterialEdit.vue'
import MetalSnapMaterialEdit from './MetalSnapMaterialEdit.vue'
import TapeMaterialEdit from './TapeMaterialEdit.vue'
import ZipperMaterialEdit from './ZipperMaterialEdit.vue'

export default {
  components: {
    ButtonMaterialEdit,
    DrawcordMaterialEdit,
    ElasticMaterialEdit,
    FabricMaterialEdit,
    HangtagMaterialEdit,
    LabelMaterialEdit,
    MetalEyeletMaterialEdit,
    MetalSnapMaterialEdit,
    TapeMaterialEdit,
    ZipperMaterialEdit,
  },
  props: {
    colorOptions: {
      type: Array,
      required: false,
      default: () => [],
    },
    supplierOptions: {
      type: Array,
      required: false,
      default: () => [],
    },
    categoryType: {
      type: String,
      required: true,
    },
    section: {
      type: String,
      required: true,
    },
    submitHandler: {
      type: Function,
      required: false,
      default: null,
    },
    styleMedia: {
      type: Array,
      required: false,
      default: () => [],
    },
    fetchStyleMaterialMedia: {
      type: Function,
      required: false,
      default: null,
    },
    initData: {
      type: Object,
      required: false,
      default: () => ({
        specifics: {}, dimensions: {}, consumption: {},
      }),
    },
  },
}
</script>
