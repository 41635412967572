<template>
  <div>
    <section class="grid-view">
      <b-container>
        <b-row>
          <b-col
            v-for="media in styleMedia"
            :key="media.id"
            no-body
            class="file-card-box"
          >
            <b-card>
              <b-card-text>
                <div :class="media.is_cover ? 'image-file iscover' : 'image-file'">
                  <b-img
                    v-if="media.image"
                    :alt="`${media.name}`"
                    rounded
                    :src="media.image"
                  />
                  <b-link
                    v-if="media.file"
                    target="blank"
                    class="nopreview"
                    :href="media.file"
                  >{{ media.name }}</b-link>
                  <b-img
                    v-if="media.static_file"
                    :alt="`${media.name}`"
                    rounded
                    height="160"
                    :src="media.static_file"
                  />
                </div>
                <!-- Action Buttons -->
                <div class="item-options d-flex justify-content-between mt-2">
                  <b-button
                    size="sm"
                    variant="danger"
                    @click="removeMedia(media.id)"
                  >
                    <feather-icon icon="XIcon" />
                    <span>Remove</span>
                  </b-button>
                </div>
              </b-card-text>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <div class="d-flex justify-content-center">
      <b-jumbotron
        bg-variant="light"
        class="justify-content-center w-75 m-5"
      >
        <b-form-file
          v-model="files"
          multiple
          placeholder="Choose files or drop them here..."
          size="lg"
          @input="filesUpload"
        />
      </b-jumbotron>
    </div>

  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCardText,
  BCol,
  BContainer,
  BFormFile,
  BImg,
  BJumbotron,
  BLink,
  BRow,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BCard,
    BCardText,
    BCol,
    BContainer,
    BFormFile,
    BImg,
    BJumbotron,
    BLink,
    BRow,
  },
  props: {
    styleMedia: {
      type: Array,
      required: false,
      default: () => [],
    },
    submitHandler: {
      type: Function,
      required: false,
      default: null,
    },
    fetchStyleMaterialMedia: {
      type: Function,
      required: false,
      default: null,
    },
  },
  data() {
    return {}
  },
  setup(props) {
    const toast = useToast()

    const files = ref([])

    const filesUpload = () => {
      props.submitHandler(files)
    }

    const removeMedia = id => {
      store.dispatch('app-style/removeStyleMedia', { id })
        .then(() => {
          props.fetchStyleMaterialMedia()
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'File removed',
              icon: 'InfoIcon',
              variant: 'success',
            },
          })
        })
    }

    const setCover = (id, isCover) => {
      store.dispatch('app-style/updateStyleMedia', { id, is_cover: isCover })
        .then(() => {
          props.fetchStyleMaterialMedia()
        })
    }

    return {
      filesUpload,
      removeMedia,
      setCover,

      files,
    }
  },
}
</script>

<style lang="scss" scoped>
.file-card-box {
  flex: 0 1 300px;
  overflow:hidden;
}
.image-file img {
  object-fit: cover;
  width: 100%;
  height: 180px;
  vertical-align: middle;
  border-radius: 15px;
}
.image-file .nopreview {
  object-fit: cover;
  width: 100%;
  height: 180px;
  display:flex;
}
.noimage-file {
  margin: 0 auto 0 auto;
  height: 180px;
  border-radius: 15px;
}
.iscover {
  outline:10px solid;
  border-radius: 15px;
}
.tag {
  height: 10px;
  margin-top: 5px;
}
</style>
