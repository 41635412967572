<template>
  <material-edit />
</template>

<script>
import MaterialEdit from './MaterialEdit.vue'

export default {
  components: {
    MaterialEdit,
  },
}
</script>
